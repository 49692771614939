import React from "react"
import "./card.scss"

const Card = props => {
  return (
    <div className="content">
      <div className="card">
        <div className="card-content">{props.content}</div>
      </div>
    </div>
  )
}

export default Card
