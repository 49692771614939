import React from "react"
import "./section.scss"
import ReactTilt from "react-universal-tilt"

const Section = props => {
  return (
    // <ReactTilt
    //   settings={{
    //     exclude: /(iPod|iPhone|iPad|Android)/,
    //     shine: true,
    //     glare: true,
    //   }}
    //   className="tilt-elem my-tilt"
    // >
    <div className="section">
      <div className="section-circle">{props.icon}</div>
      <p className="section-title">{props.title}</p>
      <p className="section-content">{props.content}</p>
    </div>
    // </ReactTilt>
  )
}

export default Section
