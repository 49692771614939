import React, { Component } from "react"
import { connect } from "react-redux"
import { Link } from "gatsby"

import Lottie from "react-lottie"
import * as animationData from "../animations/home2.json"
import * as swirls from "../images/swirls.svg"

import Layout from "../components/layout"
import Card from "../components/card"
import SEO from "../components/seo"
import PageLink from "../components/pagelink"
import Section from "../components/section"
import EarthBG from "../components/earth-bg"

import { dataBIWS } from "../data/output"
import { dataOutside } from "../data/output-outside-guide"
import { dataFIG } from "../data/output-fig"
import { dataRX } from "../data/output-rx"
import { dataPE } from "../data/output-pe"

import { setUser, signOut, getUsers } from "../state/app"

import "../components/auth.scss"
import "../components/layout.scss"
import "../components/section.scss"
import "./index.scss"

const dataAllQuestions = dataOutside
  .concat(dataBIWS)
  .concat(dataFIG)
  .concat(dataRX)
  .concat(dataPE)

class IndexPage extends Component {
  componentDidMount() {
    // comment in when you want to get user emails
    // this.props.getUsers()
  }

  render() {
    const animationOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData.default,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    }

    return (
      <Layout location={this.props.location} crumbLabel="Home">
        <SEO
          title="Home"
          description="The easiest way to study for investment banking interviews with 400+
          crowdsourced technical questions segmented by topic."
        />
        <div className="hero hero-home">
          {/* <EarthBG /> */}
          <h1 className="header-1 landing-header">IB Vine</h1>
          <div className="left-sub-content-home content">
            The easiest way to study for investment banking interviews with{" "}
            <b>400+</b> crowdsourced technical questions segmented by topic.
            {this.props.isLoggedIn && (
              <div className="authed-email mt-8">
                Logged in as {this.props.user.email}
              </div>
            )}
          </div>
          <Link className="btn" to="/practice">
            Go to Practice
          </Link>
        </div>
        <div className="section-header">
          <h1 className="header-1">Ace your next interview.</h1>
        </div>
        <div className="section-row">
          <Section
            icon={
              <svg width="24" height="24">
                <rect width="24" height="24" fill="none" rx="0" ry="0" />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12 4C11.6686 4 11.4 4.26863 11.4 4.6V6.4C11.4 6.73137 11.6686 7 12 7C12.3314 7 12.6 6.73137 12.6 6.4V4.6C12.6 4.26863 12.3314 4 12 4ZM21.6055 13.4645C20.459 11.9937 16.8951 8 12 8C7.10491 8 3.54103 11.9937 2.39445 13.4645C2.14625 13.7829 2.14625 14.2171 2.39445 14.5355C3.54103 16.0063 7.10491 20 12 20C16.8951 20 20.459 16.0063 21.6055 14.5355C21.8537 14.2171 21.8537 13.7829 21.6055 13.4645ZM16.744 5.44906C16.8573 5.13768 17.2016 4.97713 17.513 5.09046C17.8244 5.2038 17.985 5.5481 17.8716 5.85949L17.256 7.55094C17.1427 7.86232 16.7984 8.02287 16.487 7.90954C16.1756 7.7962 16.015 7.4519 16.1284 7.14051L16.744 5.44906ZM6.48696 5.09046C6.79835 4.97713 7.14266 5.13768 7.25599 5.44906L7.87163 7.14051C7.98496 7.4519 7.82441 7.7962 7.51303 7.90954C7.20164 8.02287 6.85733 7.86232 6.744 7.55094L6.12836 5.85949C6.01503 5.5481 6.17558 5.2038 6.48696 5.09046ZM21.1189 7.95963C21.3319 7.70578 21.7103 7.67267 21.9642 7.88567C22.218 8.09867 22.2511 8.47713 22.0381 8.73097L20.8811 10.1099C20.6681 10.3637 20.2897 10.3968 20.0358 10.1838C19.782 9.9708 19.7489 9.59235 19.9619 9.33851L21.1189 7.95963ZM2.03581 7.88567C2.28966 7.67267 2.66811 7.70578 2.88111 7.95963L4.03813 9.33851C4.25113 9.59235 4.21802 9.9708 3.96418 10.1838C3.71033 10.3968 3.33188 10.3637 3.11888 10.1099L1.96186 8.73097C1.74886 8.47713 1.78197 8.09867 2.03581 7.88567ZM12 18.5C14.4853 18.5 16.5 16.4853 16.5 14C16.5 11.5147 14.4853 9.5 12 9.5C9.51471 9.5 7.49999 11.5147 7.49999 14C7.49999 16.4853 9.51471 18.5 12 18.5ZM12 17.3C13.8225 17.3 15.3 15.8225 15.3 14C15.3 12.1774 13.8225 10.7 12 10.7C10.1775 10.7 8.7 12.1774 8.7 14C8.7 15.8225 10.1775 17.3 12 17.3ZM12 16C13.1046 16 14 15.1045 14 14C14 12.8954 13.1046 12 12 12C10.8954 12 10 12.8954 10 14C10 15.1045 10.8954 16 12 16Z"
                  fill="#ffffff"
                />
              </svg>
            }
            title="Wisdom of the Crowd"
            content={
              <>
                <Link to="/practice">Practice</Link>
                <span>
                  {" "}
                  with 400+ questions from real interviews at firms such as
                  Goldman Sachs, Evercore, & more.
                </span>
              </>
            }
          />
          <Section
            icon={
              <svg width="24" height="24">
                <rect width="24" height="24" fill="none" rx="0" ry="0" />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3 4C3 3.44772 3.44772 3 4 3H5H16H16.5177C16.8214 3 17.1087 3.13802 17.2984 3.37513L20.7807 7.72604C20.9227 7.90338 21 8.12376 21 8.3509V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V4ZM5 4.2V10C5 10.5523 5.44772 11 6 11H15C15.5523 11 16 10.5523 16 10V4.2H16.219C16.3742 4.2 16.5206 4.27207 16.6152 4.39506L19.6962 8.39851C19.7635 8.48593 19.8 8.59315 19.8 8.70346V19.3C19.8 19.5761 19.5761 19.8 19.3 19.8H4.7C4.42386 19.8 4.2 19.5761 4.2 19.3V4.7C4.2 4.42386 4.42386 4.2 4.7 4.2H5ZM6.2 4.2H14.8V9.3C14.8 9.57614 14.5761 9.8 14.3 9.8H6.7C6.42386 9.8 6.2 9.57614 6.2 9.3V4.2ZM12.5 5C12.2239 5 12 5.22386 12 5.5V8.5C12 8.77614 12.2239 9 12.5 9H13.5C13.7761 9 14 8.77614 14 8.5V5.5C14 5.22386 13.7761 5 13.5 5H12.5Z"
                  fill="#ffffff"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear"
                    x1="11"
                    y1="1"
                    x2="7.04888"
                    y2="14.5814"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0.0288666" stopColor="#FEA41C"></stop>
                    <stop offset="0.223691" stopColor="#FF5F61"></stop>
                    <stop offset="0.408517" stopColor="#FF2FA9"></stop>
                    <stop offset="0.628457" stopColor="#FE1CD5"></stop>
                    <stop offset="0.733043" stopColor="#CD1CEE"></stop>
                    <stop offset="0.864847" stopColor="#8C23F9"></stop>
                    <stop offset="0.96226" stopColor="#4635FF"></stop>
                  </linearGradient>
                </defs>
              </svg>
            }
            title="Save for Later"
            content={
              this.props.isLoggedIn ? (
                <span>
                  Flag questions across 4 collections and 8 technical topics
                  that you may want to return to later.
                </span>
              ) : (
                <>
                  <Link to="/sign-up">Create an account</Link>
                  <span>, or </span>
                  <Link to="/sign-in">sign in</Link>
                  <span>
                    {" "}
                    with Google / Facebook, to flag questions you may want to
                    return to later.
                  </span>
                </>
              )
            }
          />
          <Section
            icon={
              <svg width="24" height="24">
                <rect width="24" height="24" fill="none" rx="0" ry="0" />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M18.0001 2H6.00006V11H11.6001L13.0143 9.58579C13.3894 9.21071 13.8981 9 14.4285 9H18.0001V2ZM12.0001 4H8.00006V5.2H12.0001V4ZM16.0001 7.6V6.4H8.00006V7.6H16.0001ZM19.2001 8H19.0001V10H14.8286C14.2981 10 13.7894 10.2107 13.4143 10.5858L12.0001 12H5.20009V9H5.00009C4.4478 9 4.00009 9.44772 4.00009 10V12.0054C3.45987 12.0616 3.0547 12.5461 3.11004 13.0995L3.91004 21.0995C3.96116 21.6107 4.39132 22 4.90508 22H19.08C19.6001 22 20.0334 21.6013 20.0765 21.083L20.9099 11.083C20.9502 10.5996 20.6406 10.1731 20.2001 10.0416V9C20.2001 8.44772 19.7524 8 19.2001 8Z"
                  fill="#ffffff"
                />
              </svg>
            }
            title="Resources"
            content={
              <>
                <span>View our repository of </span>
                <Link to="/resources">additional resources</Link>
                <span>
                  {" "}
                  to help you navigate and conquer the recruitment process.
                </span>
              </>
            }
          />
        </div>
        <div className="home-button">
          <Link
            className="btn"
            to={this.props.isLoggedIn ? "/practice" : "/sign-up"}
          >
            Get Started
          </Link>
        </div>
        <div className="contact">
          <div className="header-1">Have comments or feedback?</div>
          <p>
            Email{" "}
            <a
              href="mailto:ibvine-support@umich.edu?subject=IB Vine Feedback"
              target="_blank"
            >
              ibvine-support@umich.edu
            </a>
          </p>
        </div>
      </Layout>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.app.user,
    isLoggedIn: state.app.isLoggedIn,
    firebaseQuestions: state.app.firebaseQuestions,
  }
}

export default connect(mapStateToProps, { setUser, signOut, getUsers })(
  IndexPage
)
